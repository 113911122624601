








































import {Vue, Component} from "vue-property-decorator";
import {UserStored} from "@/request/customer/UserStored";
import {MemberSet} from "@/request/customer/MemberSet";

@Component({
    name: 'customer_userStored'
})
export default class customer_userStored extends Vue {
    modal: any = UserStored;
    chargeModal: any = {
        show: false,
        loading: true,
        id: '',
        memberLv: 0,
        rechargeFee: 1,
        giveFee: 0,
        memberLvList: []
    };
    consumeModal: any = {
        show: false,
        loading: false,
        id: '',
        consumeFee: 1,
        consumeDesc: ''
    };
    search: Array<any> = [
        {
            type: 'Input',
            label: '昵称',
            key: 'nickName',
            width: '120px',
            clearable: true,
            placeholder: '请填写昵称'
        },
        {
            type: 'Input',
            label: '电话',
            key: 'phone',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'Select',
            label: '会员等级',
            key: 'lv',
            clearable: true,
            width: '120px',
            items() {
                return new Promise(resolve => {
                    MemberSet.getAll().then((body: any) => {
                        let data: any = body.data;
                        resolve(data.map((j: any) => {
                            return {
                                label: j.name,
                                value: j.lv
                            }
                        }));
                    })
                })
            }
        },
        {
            type: 'DatePicker',
            pickerType: 'datetimerange',
            format: 'yyyy-MM-dd HH:mm',
            label: '注册时间',
            width: '220px',
            placeholder: '请选择注册时间'
        }
    ];
    columns: Array<any> = [
        {
            title: '头像',
            key: 'avatarUrl',
            minWidth: 80,
            render: (h: any, params: any) => {
                return h('img', {
                    style: {
                        borderRadius: '3px',
                        height: '40px',
                        width: '40px',
                        marginTop: '5px'
                    },
                    attrs: {
                        src: params.row.avatarUrl
                    }
                })
            }
        },
        /*{
            title: '姓名',
            key: 'name',
            minWidth: 120
        },*/
        {
            title: '昵称',
            key: 'nickName',
            minWidth: 140
        },
        {
            title: '性别',
            key: 'gender',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.gender === 1? '男': p.row.gender === 2? '女': '未知')
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '会员等级',
            key: 'memberName',
            minWidth: 120
        },
        {
            title: '成长值',
            key: 'growth',
            minWidth: 120
        },
        {
            title: '充值金额',
            key: 'storeTotalFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', (p.row.storeTotalFee / 100).toFixed(2))
        },
        {
            title: '赠送金额',
            key: 'giveFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', (p.row.giveFee / 100).toFixed(2))
        },
        {
            title: '当前金额',
            key: 'storeCurFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', (p.row.storeCurFee / 100).toFixed(2))
        },
        {
            title: '消费总金额',
            key: 'useFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', (p.row.useFee / 100).toFixed(2))
        },
        {
            title: '积分',
            key: 'totalIntegral',
            minWidth: 120
        },
        {
            title: '当前剩余积分',
            key: 'curIntegral',
            minWidth: 120
        },
        {
            title: '已使用积分',
            key: 'useIntegral',
            minWidth: 120
        },
        {
            title: '交易笔数',
            key: 'transNum',
            minWidth: 120
        },
        /*{
            title: '生日',
            key: 'birthday',
            minWidth: 120
        },
        {
            title: '职位',
            key: 'career',
            minWidth: 120
        },
        {
            title: '公司',
            key: 'company',
            minWidth: 120
        },
        {
            title: 'email',
            key: 'email',
            minWidth: 140
        },
        {
            title: '微信号',
            key: 'wx',
            minWidth: 120
        },
        {
            title: '客户具体地址',
            key: 'addressStr',
            minWidth: 160
        },
        {
            title: '备注',
            key: 'remark',
            minWidth: 120
        },*/
        {
            title: '最近登陆时间',
            key: 'lastLoginTime',
            minWidth: 180
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    activated() {
        MemberSet.getAll().then((body: any) => {
            if (body.code === 0) {
                this.chargeModal.memberLvList = body.data;
            }
        })
    }
    charge(row: any) {
        this.chargeModal.show = true;
        this.chargeModal.id = row.id;
    }
    doCharge() {
        let self: any = this;
        let params: any = {
            userId: this.chargeModal.id,
            memberLv: this.chargeModal.memberLv,
            rechargeFee: this.chargeModal.rechargeFee * 100,
            giveFee: this.chargeModal.giveFee * 100
        }
        if (params.rechargeFee === 0) {
            this.$Message.warning('请输入充值金额');
            this.$nextTick(() => this.chargeModal.loading = false);
            return;
        }
        this.$Message.loading('请稍后');
        UserStored.charge(params).then((body: any) => {
            if (body.code === 0) {
                this.$Message.destroy();
                this.chargeModal.show = false;
                this.$Message.info('充值成功');
                self.$refs['el-table'].flush();
            }
        })
    }
    consume(row: any) {
        this.consumeModal.show = true;
        this.consumeModal.id = row.id;
    }
    doConsume() {
        let self: any = this;
        let params: any = {
            userId: this.consumeModal.id,
            consumeFee: this.consumeModal.consumeFee * 100,
            consumeDesc: this.consumeModal.consumeDesc
        };
        if (params.consumeFee === 0) {
            this.$Message.warning('请输入消费金额');
            this.$nextTick(() => this.consumeModal.loading = false);
            return;
        }
        this.$Message.loading('请稍后');
        UserStored.consume(params).then((body: any) => {
            if (body.code === 0) {
                this.$Message.destroy();
                this.consumeModal.show = false;
                this.$Message.info('已消费');
                self.$refs['el-table'].flush();
            }
        })
    }
}
