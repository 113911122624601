import {BaseRequest} from "@/request/BaseRequest";

class MemberSet extends BaseRequest {

    public requestPath: string = '/customer/memberSet';

    /**
     * 获取成长值配置
     */
    getGrowth(): any {
        return this.get(`${this.requestPath}/growthGet`)
    }

    /**
     * 设置成长值
     * @param val
     */
    setGrowth(val: any): any {
        return this.post(`${this.requestPath}/growthSet/${val}`)
    }

    /**
     * 删除
     * @param id
     */
    delRow(id: string): any {
        return this.del( [id]);
    }

}

const c = new MemberSet();
export {c as MemberSet};