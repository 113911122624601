import {BaseRequest} from "@/request/BaseRequest";

class UserStored extends BaseRequest {

    public requestPath: string = '/customer/userStored';

    /**
     * 充值
     * @param data
     */
    charge(data: {
        userId: string,
        rechargeFee: number,
        memberLv: number,
        giveFee?: number
    }): any {
        return this.post(`${this.requestPath}/recharge`, data);
    }

    /**
     * 消费
     * @param data
     */
    consume(data: {
        userId: string,
        consumeFee: number,
        consumeDesc: ''
    }): any {
        return this.post(`${this.requestPath}/consume`, data);
    }

}

const c = new UserStored();
export {c as UserStored};